<template>
  <section class="index-ecosystem">
    <div class="index-ecosystem__content">
      <div class="index-ecosystem__title-wrapper container">
        <h2 class="index-ecosystem__title" v-t="'index.ecosystem.title'" />
        <p class="index-ecosystem__text">
          <span v-t="'index.ecosystem.text'" />
          <a v-if="false" :href="roobeeEcosystemLink" v-t="'index.ecosystem.roobee-ecosystem'" />
        </p>
      </div>
      <div class="index-ecosystem__container container" ref="container">
        <div class="index-ecosystem__products">
          <div class="index-ecosystem__products-item" v-for="(item, i) in ecosystemProducts" :key="i">
            <img :src="`/images/ecosystem/${item.slug}.${item.isSvg ? 'svg' : 'png'}`" :class="item.slug" :alt="item.title" class="icon">
            <span class="hashtag">#{{ $t(`index.ecosystem.hashtags.${item.hashtag}`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Ecosystem",

  props: ['ecosystemProducts'],

  data() {
    return {
      roobeeEcosystemLink: '#'
    }
  },

  mounted() {
    // horizontal scrolling with mouse click
    const slider = this.$refs.container;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/ecosystem';
</style>